<script setup lang="ts">
import { useSettlementObjects } from '~/layers/settlement/composables/useSettlementObjects';
import { useAcceptances } from '~/layers/settlement/composables/acceptances/useAcceptances';
import { useAcceptanceDialog } from '~/layers/settlement/composables/useAcceptanceDialog';
import InformationDialog from '@frontend/ui-kit/src/components/Base/InformationDialog.vue';
import Button from '@frontend/ui-kit/src/components/Base/Button.vue';
import Dropdown from '@frontend/ui-kit/src/components/Base/Dropdown.vue';
import TextInput from '@frontend/ui-kit/src/components/Base/TextInput.vue';
import Fieldset from '@frontend/ui-kit/src/components/Base/Fieldset.vue';
import DateInput from '@frontend/ui-kit/src/components/Base/DateInput.vue';
import TextArea from '@frontend/ui-kit/src/components/Base/TextArea.vue';
import AcceptanceDialogOwners from '~/layers/settlement/components/admin/acceptances/AcceptanceDialogOwners.vue';
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { useNotificationStore } from '~/stores/alert/useNotificationStore';
import { AcceptanceStatuses, AcceptanceUIStatuses } from '~/layers/settlement/constants/realty';
import type { IAcceptanceDialog, IAcceptanceDialogInfo, IAcceptanceDialogSend } from '~/layers/settlement/types/acceptance';
import { useAcceptancesFilters } from '~/layers/settlement/composables/acceptances/useAcceptancesFilters';
import realtyHelper from '~/utils/realtyHelper';
import type { IAcceptanceTime } from '~/layers/settlement/types/acceptanceDates';
import { useVuelidate } from '@vuelidate/core';
import { useI18nValidators } from '~/plugins/i18n/i18n-validators';
import type { IStringKeyValueData } from "@frontend/ui-kit/src/types/keyValue"; 
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';

interface Props {
    acceptanceId: number | null; // айди приемки
}

const props = withDefaults(defineProps<Props>(), {});

const model = defineModel<boolean>();

const authStore = useAuthStore();
const { userId } = storeToRefs(authStore);
const notificationStore = useNotificationStore();
const { getAcceptanceStatusApiFilter } = useAcceptancesFilters();

const { t } = useI18n();

const { getAcceptanceUIStatusColor } = useSettlementObjects();
const { 
    formatDate,
} = useAcceptances();

const { 
    getFullAcceptanceInfo,
    updateAcceptanceInfo,
    createInfoArray,
    createManagerArray,
    getAcceptanceStatuses,
    defaultAcceptanceData,
    getFullAddress,
    mapFromApiStatus,
    getAcceptanceTimes,
    getAcceptanceDates,
    setValidate,
} = useAcceptanceDialog();

const { transformDateSlots, transformTimeSlots } = useSettlementDate();

const emit = defineEmits(["saved"]);  

// объект для отображения данных на странице
const data = ref<IAcceptanceDialog>(defaultAcceptanceData);  

const selectedDate = ref<Date | null>(null);
const selectedTime = ref<string | null>(null);
const dates = ref<Date[]>([]); // даты для выбора времени приемки
const times = ref<IAcceptanceTime[]>([]); // доступное время в конкретный день

// Фильтруем массив, чтобы убрать ненужные статусы
const statuses = ref<IStringKeyValueData[]>(getAcceptanceStatuses());
const status = ref<string | null>(null);

// Текстовое название типа объекта
const realtyTypeName = computed<string >(() => {
    if (!data.value.realtyType && data.value.realtyType !== 0) {
        return t('settlement.realty_types.undefined');
    }

    return realtyHelper.getRealtyTypeText(t, data.value.realtyType);
});

// полный текст адреса
const fullAddress = computed<string>(() => {
    return getFullAddress(
        data.value.address,
        realtyTypeName.value, 
        data.value.floorNumber, 
        data.value.number
    )
});

// выбранный менеджер из списка
const currentManagerId = ref<string | null>(null); 

const updatedData = computed<IAcceptanceDialogSend>(() => {
    const status = data.value.acceptanceStatus;
    const time = selectedTime.value;
    const managerId = currentManagerId.value;
    const comment = data.value.technicalComment;
    const remarks = data.value.remarks;

    const dataObject:IAcceptanceDialogSend = {
        status: status ? status : AcceptanceStatuses.Undefined,
        dateTime: time ? time : undefined,
        managerId: managerId ? managerId : null,
        accepter: data.value.accepter,
        technicalComment: comment ? comment : '',
        remarks: remarks ? remarks : '',
    }
    
    return dataObject;
}); 

const isLoadingDialog = ref<boolean>(true);

// текущий цвет селекта со статусом
const statusColor = computed<string>(() => {
    if(!status.value) {
        return '';
    }
    return getAcceptanceUIStatusColor(status.value);
});

// площадь объекта + м2
const fullSquare = computed<string>(() => {
    return `${data.value.square}${t('forms.shared.dimensions.meters2')}`;
});

const fetchData = async () => {   
    isLoadingDialog.value = true;  

    if (!props.acceptanceId || !userId.value) {
        isLoadingDialog.value = false;
        return;
    } 

    const response = await getFullAcceptanceInfo(props.acceptanceId);
    if (response && response.data) {
        const responseData = response.data;
        data.value = responseData;
        if (!responseData.acceptanceDate) {
            await setAcceptanceDates();
        }
    }

    currentManagerId.value = data.value.managerId;
    status.value = data.value.acceptanceStatus ? mapFromApiStatus(data.value.acceptanceStatus) : AcceptanceUIStatuses.Undefined;

    isLoadingDialog.value = false;
}

onMounted(async () => {
    await fetchData();
});

// список информации об объекте
const info = computed<IAcceptanceDialogInfo[]>(() => {
    return createInfoArray(data.value, fullSquare.value, realtyTypeName.value);
});

// селект менеджера
const managers = computed<IStringKeyValueData[]>(() => {
    return createManagerArray(data.value)
});

// подгружаем даты в датапикер, если пришла пустая дата приемки
const setAcceptanceDates = async () => {
    const result = await getAcceptanceDates(data.value.acceptanceId);
    if (!result.isError) {
        const resultData = result.data!;

        if(resultData.selectedDateTime 
            && resultData.selectedDateTime.length > 0) {
            selectedDate.value = new Date(resultData.selectedDateTime);
        }

        await loadAcceptanceTimes();

        dates.value = transformDateSlots(resultData.allowedDates);
        selectedTime.value = resultData.selectedDateTime;
    }
};

// подгружаем время при выборе даты
const onDateUpdating = async () => {
    await loadAcceptanceTimes();
    if(times.value && times.value.length > 0) {
        selectedTime.value = times.value?.[0].type; 
    } else {
        selectedTime.value = null
    }
};

// получает массив времени на основе даты 
const loadAcceptanceTimes = async () => {
    if (!selectedDate.value) {
        return;
    }

    const responseTimes = await getAcceptanceTimes(data.value.acceptanceId, dateTimeHelper.toDateOnly(selectedDate.value));
    if (responseTimes && responseTimes.data) {
        times.value = transformTimeSlots(responseTimes.data);
    } 
}; 

const { required } = useI18nValidators();
const rules = computed(() => ({
    date: { 
        required,
    },
    time: { required },
}));
const $v = useVuelidate(rules, { date: selectedDate, time: selectedTime });

const onSave = async () => {
    if (!props.acceptanceId) {
        console.error('failed send full acceptance info');
        return;
    }

    const { isFormCorrect, message } = setValidate(
        data.value.acceptanceStatus, 
        status.value, 
        selectedDate.value, 
        selectedTime.value
    );
    if(!isFormCorrect) {
        notificationStore.showErrorText(t, message);
        return;
    }
    data.value.acceptanceStatus = getAcceptanceStatusApiFilter(status.value)[0];

    const result = await updateAcceptanceInfo(props.acceptanceId, updatedData.value);
    if(!result.isError) {
        notificationStore.showSuccessText(t, t('forms.shared.phrases.dataSaved'));
        model.value = false;
    }
    emit("saved");
}

const onCloseDialog = () => {
    model.value = false;
};
</script>

<template>
    <InformationDialog
        v-model="model"
        :maxWidth="495"
        styleClass="appointment-dialog"
        :showButton="true"
        @closeDialog="onCloseDialog"
    >            
        <template #content>
            <v-skeleton-loader
                v-if="isLoadingDialog"
                color="neutral04"
                class="loader"
                type="article, text"
            />
            <div v-else-if="data.acceptanceId" class="container">
                <div id="title" class="title">
                    <div class="title-text">
                        <span>{{ realtyTypeName }}&nbsp;</span>
                        <span>{{ data.number }}&nbsp;</span>
                        <span v-html="$t('forms.shared.dimensions.meters2')"></span> 
                    </div>
                    <div class="address">
                        {{ fullAddress }}
                    </div>
                    <div class="status">
                        <Dropdown
                            id="dialog-status"
                            v-model="status"
                            :placeholder="$t('settlement.admin.acceptances.placeholders.status')"
                            :items="statuses"
                            :color="statusColor"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="content">                
                    <div v-if="data.schemeUrl" class="card-bg content-img">
                        <img :src="data.schemeUrl">
                    </div>
                    <div class="card-bg content-info">
                        <div 
                            v-for="item in info"
                            :key="`info-${item.icon}`"
                            class="content-info__item"
                        >
                            <i :class="[item.icon, 'item-icon']"></i>
                            <div class="item-title">{{ item.title }}</div>
                            <div class="item-value" v-html="item.value"></div>
                        </div>
                    </div>
                    <div class="card-bg content-deal">
                        <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.deal') }}</div>
                        <div class="card-section">
                            <div class="content-deal__date">{{ $t('settlement.admin.acceptances.dialog_titles.date') }}</div>
                            <div 
                                v-if="data.acceptanceDate"
                                class="content-deal__text"
                                v-html="formatDate(data.acceptanceDate)">
                            </div>
                            <div v-else class="content-deal__form">
                                <Fieldset 
                                    styleClass="input-wrapper"
                                    :title="$t('forms.shared.date.label')" 
                                >
                                    <DateInput 
                                        id="date-dropdown"
                                        v-model="selectedDate"
                                        :placeholder="$t('forms.shared.date.placeholder')"
                                        :allowedDates="dates"
                                        :errors="$v.date.$errors"
                                        @changed="onDateUpdating"
                                    />
                                </Fieldset>
                                <Fieldset 
                                    styleClass="input-wrapper"
                                    :title="$t('forms.shared.time.label')" 
                                >
                                    <Dropdown
                                        id="time-dropdown"
                                        v-model="selectedTime"
                                        :placeholder="$t('forms.shared.time.placeholder')"
                                        :items="times"
                                        :disabled="!times.length"
                                        :clearable="false"
                                    />
                                </Fieldset>
                            </div>
                        </div>
                        <div class="card-section">
                            <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.manager') }}</div>
                            <Dropdown 
                                id="deal-manager"
                                v-model="currentManagerId"
                                class="content-deal__manager"
                                :placeholder="$t('settlement.admin.acceptances.placeholders.manager')"
                                :items="managers"
                            />
                        </div>
                        <div class="card-section">
                            <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.accepter') }}</div>
                            <TextInput 
                                id="deal-accepter"
                                v-model="data.accepter"
                                class="content-deal__accepter"
                                :placeholder="$t('settlement.admin.acceptances.placeholders.accepter')"
                            />
                        </div>
                    </div>
                    <div v-if="data.owners.length" class="card-bg content-owners">
                        <div class="card-section">
                            <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.owners') }}</div>
                            <div class="card-section">
                                <AcceptanceDialogOwners :owners="data.owners"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-bg content-comment">
                        <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.comment') }}</div>
                        <TextArea 
                            id="content-comment"
                            v-model="data.technicalComment"
                            class="content-comment__input"
                            :placeholder="$t('settlement.admin.acceptances.placeholders.comment')"
                        />
                    </div>
                    <div class="card-bg content-remarks">
                        <div class="card-title">{{ $t('settlement.admin.acceptances.dialog_titles.remarks') }}</div>
                        <TextArea 
                            id="content-remarks"
                            v-model="data.remarks"
                            class="content-remarks__input"
                            :placeholder="$t('settlement.admin.acceptances.placeholders.remarks')"
                        />
                    </div>
                </div>
            </div>
            <div v-else>{{ $t('error.data') }}</div>
        </template>
        <template #actions>
            <Button 
                v-if="data"
                styleClass="dialog-button"
                @click="onSave"
            >
                {{ $t('forms.shared.phrases.save') }}
            </Button>
            <Button 
                v-else
                styleClass="dialog-button"
                @click="onCloseDialog"
            >
                {{ $t('forms.shared.back') }}
            </Button>
        </template>
    </InformationDialog>
</template>

<style scoped lang="scss">
.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Padding-pa-3, 12px);
    align-self: stretch;

    &-text {
        color: var(--Color-Zink-950, $main-active-color);

        font-family: $ff-black;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 125%;
        letter-spacing: -0.24px;
    }

    .status {
        margin-top: 12px;
        width: 100%;
    }
}

.card-bg {
    margin-top: 4px;
    padding: 20px 16px;
    width: 100%;
    background-color: $color-main;
    border-radius: 12px;
    border: 1px solid $color-neutral01;
}

.card-title {
    font-family: $ff-bold;
    font-size: 14px;
    line-height: 125%; 
    letter-spacing: -0.14px;
    text-transform: uppercase;
    color: $color-neutral03;
}

.card-section {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $border-color01;

    &:last-child {
        border-bottom: 0;
    }
}

.content {
    margin-top: 40px;

    &-img {
        padding: 20px 0;

        img {
            width: 100%;
        }
    }

    &-info {
        &__item {
            padding: 16px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color01;
            
            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &-deal { 
        &__date {
            color: $color-neutral03;
        }

        &__text {
            margin-top: 4px;
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}

.item-icon {
    font-size: 24px;
    color: $color-primary02;
}

.item-title {
    padding: 0 16px;
    flex-grow: 1;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.16px;
    color: $color-neutral03;
}

.item-value {
    text-align: right;
    flex-grow: 0;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.16px;
}

.dialog-button {
    width: 100%;
}
</style>