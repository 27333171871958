import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type { IAcceptancesData } from "~/layers/settlement/types/acceptancesTable";
import moment from 'moment';
import type { IManagerAcceptanceGridFilter } from "~/layers/settlement/types/list/filter";

export const useAcceptances = () => {
    const { t } = useI18n();

    const { post } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();

    /**
    * Получает полный объект для таблицы со всей информацией
    * @param filter Фильтр.
    * @param take Количество записей, которое необъодимо вернуть.
    * @param skip Количество записей, которое необходимо пропустить.
    */
    const getData = async (filter: IManagerAcceptanceGridFilter, take: number, skip: number) : Promise<Result<IAcceptancesData>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/manager/list`;
            return await post<IAcceptancesData>(url, {...filter, take, skip });
        });
    };

    /** форматирует дату в формат для отображения в таблице */
    const formatDate = (date: string | Date | null) : string => {
        if(!date) {
            return '';
        }

        return moment(date).format('DD MMMM') + ' &bull; ' + moment(date).format('HH:mm');
    }

    /**  Возвращает полный адрес */
    const getFullAddress = (
        address: string, 
        realtyTypeName: string,
        floorNumber: number,
        number: string
    ) : string => {
        return `${address}, ${t('settlement.common.floor')} ${floorNumber}, ${realtyTypeName} ${number}`;
    }

    return {
        getData,
        formatDate,
        getFullAddress,
    };
};